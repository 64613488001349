import React from 'react';
import { string, shape } from 'prop-types';
import { StyledLabel } from '@vpp-frontend-components/styled-label';
import { Icon } from '@vpp-frontend-components/icon';

const namespace = 'ui-seller-data-status';

const Info = ({ title, icon, subtitle }) => (
  <div className={`${namespace}__info`}>
    {title && (
      <StyledLabel
        as="p"
        text={title.text}
        color={title.color}
        font_size={title.font_size}
        font_family={title.font_family}
        className={`${namespace}__info-title`}
      />
    )}
    {icon && <Icon id={icon.id} className={`${namespace}__info-icon`} />}
    <StyledLabel
      as="p"
      text={subtitle.text}
      color={subtitle.color}
      font_size={subtitle.font_size}
      font_family={subtitle.font_family}
      className={`${namespace}__info-subtitle`}
    />
  </div>
);

Info.propTypes = {
  title: string,
  icon: shape({}),
  subtitle: string.isRequired,
};

Info.defaultProps = {
  title: null,
  icon: null,
};

export default Info;
